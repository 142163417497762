import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { ContentRoute } from "../_metronic/layout";
import SearchPage from "./pages/search-identifiers/SearchPage";

const DashboardPage = lazy(() => import("./pages/dashboard/DashboardPage"));
const Messages = lazy(() => import("./pages/bdi-messages/MessagesPage"));
const Agreements = lazy(() => import("./pages/bdi-messages/AgreementsPage"));
const NewAgreement = lazy(() => import("./pages/bdi-messages/NewAgreement"));
const AgreementDetails = lazy(() =>
  import("./pages/bdi-messages/AgreementDetails")
);
const Inventory = lazy(() => import("./pages/inventory-page/InventoryPage"));
const ItemDetailsPage = lazy(() =>
  import("./pages/item-details-page/ItemDetailsPage")
);
const SplitItemPage = lazy(() =>
  import("./pages/item-split-page/ItemSplitPage")
);
const TransferItemDetailsPage = lazy(() =>
  import("./pages/transfer-item-details-page/TransferItemDetailsPage")
);
const ShareItemDetailsPage = lazy(() =>
  import("./pages/share-item-details-page/ShareItemDetailsPage")
);
const InventoryCreateItemPage = lazy(() =>
  import("./pages/inventory-create-item/InventoryCreateItemPage")
);
const CreateGroup = lazy(() => import("./pages/item-groups/CreateGroup"));
const GlobalItemsPage = lazy(() =>
  import("./pages/global-items/GlobalItemsPage")
);

const SupplyChainTool = lazy(() =>
  import("./pages/supply-chain/SupplyChainTool")
);
const ItemGroupsPage = lazy(() => import("./pages/item-groups/ItemGroupsPage"));
const ItemGroupDetails = lazy(() =>
  import("./pages/item-groups/ItemGroupDetails")
);
//const GlobalItemDetailsPage = lazy(() => import ("./pages/global-items/GlobalItemDetailsPage"));
const PendingItemDetailsPage = lazy(() =>
  import("./pages/item-details-page/PendingItemDetailsPage")
);
const TemplateLibrary = lazy(() =>
  import("./pages/template-library/TemplateLibrary")
);
const MergeItemPage = lazy(() =>
  import("./pages/items-merge-page/ItemsMergePage")
);
const BusinessDataAnalytics = lazy(() =>
  import("./pages/business-data-analytics/BusinessDataAnalytics")
);
const LifecycleAssesment = lazy(() => import("./pages/lca/LifecycleAssesment"));
const LifecycleAssesmentReports = lazy(() =>
  import("./pages/lca/LifecycleAssesmentReports")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.error('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<></>}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route exact path="/messages" component={Messages} />
        <Route exact path="/agreements" component={Agreements} />
        <Route exact path="/agreements/new" component={NewAgreement} />
        <Route exact path="/agreements/details" component={AgreementDetails} />
        <Route exact path="/inventory" component={Inventory} />
        <Route exact path="/inventory/item/:id" component={ItemDetailsPage} />
        <Route
          exact
          path="/inventory/split/item/:id"
          component={SplitItemPage}
        />
        <Route exact path="/inventory/merge-items" component={MergeItemPage} />
        <Route
          exact
          path="/inventory/transfer/item/:id"
          component={TransferItemDetailsPage}
        />
        <Route
          exact
          path="/inventory/share/item/:id"
          component={ShareItemDetailsPage}
        />
        <Route
          exact
          path="/inventory/create-new-item"
          component={InventoryCreateItemPage}
        />
        <Route
          exact
          path="/inventory/create-new-group"
          component={CreateGroup}
        />
        <Route
          exact
          path="/inventory-pending/item/:id"
          component={PendingItemDetailsPage}
        />
        <Route exact path="/template-library" component={TemplateLibrary} />
        <Route exact path="/global-items" component={GlobalItemsPage} />
        <Route exact path="/item-groups" component={ItemGroupsPage} />
        <Route exact path="/search-identifiers" component={SearchPage} />
        <Route exact path="/supply-chain" component={SupplyChainTool} />

        <Route
          exact
          path="/item-groups/group/:id"
          component={ItemGroupDetails}
        />
        <Route
          exact
          path="/global-items/item/:id"
          component={ItemDetailsPage}
        />
        <Route
          exact
          path="/business-data-analytics"
          component={BusinessDataAnalytics}
        />
        <Route
          exact
          path="/life-cycle-assessment/inputs"
          component={LifecycleAssesment}
        />
        <Route
          exact
          path="/life-cycle-assessment/reports"
          component={LifecycleAssesmentReports}
        />

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
